import React, { Fragment, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import isNil from "lodash/isNil";

import { Table } from "~/components/Table";
import { Typography } from "~/components/Typography";
import { DisplayOverride } from "./DisplayOverride";

interface ICriticalErrorProps {
    message: string;
    error: string;
}

export const CriticalError: React.FC<ICriticalErrorProps> = ({
    message,
    error,
}) => (
    <DisplayOverride>
        <Typography variant="h2" component="div">
            {message}
        </Typography>
        <Typography variant="body1" color="error">
            {error}
        </Typography>
    </DisplayOverride>
);