import { useCompanyMeta } from "@api/companies";
import React, {
    createContext,
    useContext,
} from "react";

type GeneralSettingsContext = ReturnType<typeof useGeneralSettingsContextValue>;

export type MethodCategory = {
    title: string;
    empty?: string;
    forceReadOnly: boolean;
}

export type Method = {
    email: string;
    loading: boolean;
}

export type MethodDisplay = {
    readOnly: boolean;
    values: Method[];
}

const GeneralSettingsContext = createContext<GeneralSettingsContext>({} as GeneralSettingsContext);

export const useGeneralSettingsContext = () => {
    const context = useContext(GeneralSettingsContext);
    if (!context) {
        throw new Error("You cannot use GeneralSettingsContext from outside of its Provider");
    }

    return context;
};

export const defaultEmptyMessage = (type: string) => `You do not have any ${type} delivery methods`;

const useGeneralSettingsContextValue = () => {
    const {
        actions,
        ...rest
    } = useCompanyMeta();

    const setEnableMarketingCampaigns = (v: boolean) => {
        actions.updateCompanyMeta({
            marketing: {
                enableCampaigns: v,
            },
        });
    };

    return { ...rest, actions: { ...actions, setEnableMarketingCampaigns }};
};

export const GeneralSettingsProvider: React.FC = ({ children }) => {
    const contextValue = useGeneralSettingsContextValue();

    return (
        <GeneralSettingsContext.Provider value={contextValue}>
            {children}
        </GeneralSettingsContext.Provider>
    );
};