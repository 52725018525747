import React from "react";

import { createSettingsView, ViewComponent } from "@settings/view";

import { GeneralSettingsProvider, GeneralSettingsDisplay } from "./_lib";

const General: ViewComponent = () => {
    return (
        <GeneralSettingsProvider>
            <GeneralSettingsDisplay />
        </GeneralSettingsProvider>
    );
};

export default createSettingsView(General, {
    title: "General",
    internalPad: true,
    internalScroll: true,
});