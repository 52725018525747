import React, { Fragment, ReactNode, useMemo, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import CompanyPanel from "~/components/CompanyPanel";
import { GlobalLoader } from "@settings/GlobalLoader";
import { AutocorpBlock } from "@settings/AutocorpBlock";
import { CriticalError } from "@settings/CriticalError";

import { useAuth } from "@api/auth";
import { useCompanyMeta } from "@api/companies";

import {
    useGeneralSettingsContext,
} from "./context";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import { Typography } from "~/components/Typography";
import { Redirect } from "~/components/Redirect";
import { Button, Input, InputLabel } from "@material-ui/core";

const useStyles = makeStyles(({ dashboard }) => ({
    container: {
        display: "flex",
        flexFlow: "row nowrap",
        height: "100%",
    },
    content: {
        padding: dashboard?.innerContent?.paddingTop,
        paddingBottom: 0,
        overflow: "auto",
        height: "max-content",
        maxHeight: "100%",
        width: "100%",
        margin: 0,
        position: "relative",
    },
    table: {
        overflow: "hidden",
    },
    tableRow: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    deleteIcon: {
        color: "red",
        fontSize: 24,
        marginLeft: 8,
        cursor: "pointer",
    },
    separator: {
        borderBottom: "3px solid #EEEEEE",
        marginTop: "10px",
        marginBottom: "20px",
    },
}));

const Separator = () => {
    const styles = useStyles();
    return (
        <Grid item xs={12} className={styles.separator} />
    );
};

export function MarketingSettings() {
    const { state, actions } = useGeneralSettingsContext();
    const value = !!state?.marketing?.enableCampaigns;

    return (
        <Toggle
            title="Enable Drip Campaigns"
            helper="This setting enables automated email and sms campaigns to leads who have dropped off before completing the forms."
            value={value}
            setValue={actions.setEnableMarketingCampaigns}
        />
    );
}

interface ToggleProps {
    value: boolean;
    setValue: (value: boolean) => void;
    title: string;
    label?: ReactNode;
    helper: string;
}

function Toggle({ value, setValue, title, label, helper }: ToggleProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
    };
    const defaultLabel = value ? "Enabled" : "Disabled";

    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch checked={value} onChange={handleChange} name="disableTradeInValue" />
                    }
                    label={label ?? defaultLabel}
                />
            </FormGroup>
            <FormHelperText>{helper}</FormHelperText>
        </FormControl>
    );
}

export function InstallationIssuesBanner() {
    const styles = useStyles();
    const { actions, state } = useCompanyMeta();

    const url = state?.installation?.problemUrl;
    const [installationProblemUrl, setInstallationProblemUrl] = useState(url || "");

    const handleSubmit = async () => {
        try {
            installationProblemUrl && await actions.updateCompanyMeta({
                installation: {
                    problemUrl: installationProblemUrl,
                },
            });
        } catch (error) {
            console.error(error);
        }
    };
    const handleDelete = async () => {
        try {
            installationProblemUrl && await actions.updateCompanyMeta({
                installation: {
                    problemUrl: "",
                },
            });
            setInstallationProblemUrl("");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Fragment>
            <InputLabel>URL</InputLabel>
            <Input
                value={installationProblemUrl}
                placeholder={"Url"}
                id={"banner-setter-input"}
                onChange={(event) => setInstallationProblemUrl(event.target.value)}
            />
            <Button onClick={handleSubmit}>Save</Button>
            {url && <span onClick={handleDelete} className={styles.deleteIcon}>x</span>}
        </Fragment>
    );
}

const GeneralSettingsContent: React.FC = () => {
    return (
        <Fragment>
            <Grid item xs={6}>
                <Typography variant="h3" color="secondary">
                    Installation Issues Banner
                </Typography>

                <Typography variant="subtitle1" color="secondary">
                    This settings will display a banner on the dealer portal.
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <InstallationIssuesBanner />
            </Grid>
            <Separator />
            <Grid item xs={6}>
                <Typography variant="h3" color="secondary">
                    Marketing Settings
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <MarketingSettings />
            </Grid>
        </Fragment>
    );
};

export const GeneralSettingsDisplay: React.FC = () => {
    const styles = useStyles();
    const { error, loading } = useGeneralSettingsContext();
    const { user, selectedCompany } = useAuth();

    const content = useMemo(() => {
        if (!user?.autocorpAdmin) {
            return <Redirect to="/settings/delivery-methods/" />;
        }

        if (user?.autocorpAdmin && !selectedCompany) {
            return (
                <AutocorpBlock />
            );
        }

        let content: React.ReactNode;

        if (error) {
            content = (
                <CriticalError
                    message={"Unable to retrieve delivery methods!"}
                    error={error}
                />
            );
        } else if (loading) {
            content = <GlobalLoader />;
        } else {
            content = <GeneralSettingsContent />;
        }

        return (
            <Grid
                container
                spacing={2}
                className={styles.content}
                style={{
                    height: loading ? "100%" : undefined,
                }}
            >
                {content}
            </Grid>
        );
    }, [loading, selectedCompany, styles, user, error]);

    return (
        <Box className={styles.container}>
            {(user?.companies || []).length > 1 && (
                <CompanyPanel
                    placement="inline"
                />
            )}
            {content}
        </Box>
    );
};